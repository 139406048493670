import React, { useEffect, useState } from 'react'
import getQueryParam from 'get-query-param'

import Gradients from '../../gradients'
import { db } from '../../../lib/client'

export default () => {
  const [gradient, setGradient] = useState(null)

  useEffect(() => {
    const fetchGradient = async () => {
      const id = getQueryParam('id', window.location.href)
      const result = await db
        .collection('components')
        .doc('gradients')
        .collection('votes')
        .doc(id)
        .get()

      setGradient(result.data())
    }

    fetchGradient()
  }, [])

  if (!gradient) {
    return <h1>Loading Gradient</h1>
  }

  return (
    <Gradients
      initialGradient={gradient.data}
      initialPins={gradient.metadata.pins}
    />
  )
}
